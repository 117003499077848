@forward "./src/styles/colours";
@forward "./src/styles/common";
@forward "./src/styles/dimensions";
@use "./src/styles/theme";
@use '@angular/material' as mat;

//////////////////////////////////////////////////////////////////////////////
// Material Theme implementation - ONLY IN ONE PLACE, NEVER RE-IMPORTED
//////////////////////////////////////////////////////////////////////////////

// Include this once, and only once, in your application
@include mat.elevation-classes;
@include mat.app-background;

// This should be done in the most generic HTML tag so it is inherited by all.
:root {
  // Apply main theme to all components
  @include mat.all-component-themes(theme.$light-theme);

  // Apply main theme to all typography hierarchy, all components basically.
  @include mat.typography-hierarchy(theme.$light-theme, $back-compat: true);

  // Needed to apply system variables into colors and typography
  @include mat.system-level-colors(theme.$light-theme);
  @include mat.system-level-typography(theme.$light-theme)
}

//////////////////////////////////////////////////////////////////////////////
// Material generic classes
//////////////////////////////////////////////////////////////////////////////

@mixin set-mat-color-variant-to-all-components($theme, $variant) {
  @include mat.option-color($theme, $color-variant: $variant);
  @include mat.pseudo-checkbox-color($theme, $color-variant: $variant);
  @include mat.badge-color($theme, $color-variant: $variant);
  @include mat.button-color($theme, $color-variant: $variant);
  @include mat.icon-button-color($theme, $color-variant: $variant);
  @include mat.checkbox-color($theme, $color-variant: $variant);
  @include mat.chips-color($theme, $color-variant: $variant);
  @include mat.datepicker-color($theme, $color-variant: $variant);
  @include mat.form-field-color($theme, $color-variant: $variant);
  @include mat.icon-color($theme, $color-variant: $variant);
  @include mat.progress-bar-color($theme, $color-variant: $variant);
  @include mat.progress-spinner-color($theme, $color-variant: $variant);
  @include mat.radio-color($theme, $color-variant: $variant);
  @include mat.select-color($theme, $color-variant: $variant);
  @include mat.slide-toggle-color($theme, $color-variant: $variant);
  @include mat.slider-color($theme, $color-variant: $variant);
  @include mat.stepper-color($theme, $color-variant: $variant);
  @include mat.tabs-color($theme, $color-variant: $variant);
}

.mat-primary {
  @include set-mat-color-variant-to-all-components(theme.$light-theme, primary);

  mat-icon {
    color: inherit;
  }
}

.mat-secondary {
  @include set-mat-color-variant-to-all-components(theme.$light-theme, secondary);

  mat-icon {
    color: inherit;
  }
}

.mat-tertiary,
.mat-accent {
  @include set-mat-color-variant-to-all-components(theme.$light-theme, tertiary);

  mat-icon {
    color: inherit;
  }
}

.mat-error,
.mat-warn {
  @include set-mat-color-variant-to-all-components(theme.$light-theme, error);

  mat-icon {
    color: inherit;
  }
}

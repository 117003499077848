@use "colours";

// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: colours.$color-text;
}

// Icons inside buttons should not have margin by default
button mat-icon {
  margin: 0;
}

// Useful mixin to resize an icon, which need font, height and width to be
// changed
@mixin icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin ezc-bubble-loader {
  // Default width of 60px, it can be modified later
  width: 60px;
  aspect-ratio: 4;

  --popping: no-repeat radial-gradient(circle closest-side, #{colours.$color-primary} 90%, transparent);

  background: var(--popping) 0 50%,
  var(--popping) 50% 50%,
  var(--popping) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: bubble-popping 1s infinite linear;
}

@keyframes bubble-popping {
  33% {
    background-size: calc(100% / 3) 0, calc(100% / 3) 100%, calc(100% / 3) 100%
  }

  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0, calc(100% / 3) 100%
  }

  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0
  }
}
@use 'sass:map';
@use '@angular/material' as mat;
@import "//fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap";

//////////////////////////////////////////////////////////////////////////////
// Colour palettes
// Created using: 'ng generate @angular/material:m3-theme'
// in conjunction with https://colordesigner.io/color-palette-builder to
// design the correct primary and tertiary palettes that we want
//////////////////////////////////////////////////////////////////////////////

/*
How to use https://colordesigner.io/color-palette-builder to get Material M3
palettes?

Since the main colour for a palette is "40", we will then consider that we
need to create 6 shades (35, 30, 25, 20, 10, 0) and 9 tints (50, 60, 70, 80, 90
95, 98, 99, 100) for the Angular Material 18 (M3) colour palettes schemas.

In ColorDesign we can select how many steps we want for shades and tints.

We will select 9 shades (from 40 to 0, every 5): it will generate
40, 35, 30, 25, 20, 15, 10, 5 and 0. We pick the ones we need of course.

For tints, we will need steps from 40 to 100 every 10, so we create 7 tints:
40, 50, 60, 70, 80, 90 and 100.
But what about 95, 98 and 99? For that we will pick the color that we got for
"90" in previous step, use it as main colour, and generate 11 tints for it,
which in reality they will be 90, 91, 92, 93, 94, 95, 96, 97, 98, 99 and 100.

Repeat this process with as many colours as you want for the app: primary,
secondary, tertiary, error, etc.
 */


// Note: Color palettes are generated from primary: #0b2d50, tertiary: #fab929
$-palettes: (
  primary: (
    0: #000,
    10: #0a1019,
    20: #0f1a2a,
    25: #0f1f33,
    30: #0f233d,
    35: #0d2846,
    40: #0b2d50,
    50: #374b6b,
    60: #5d6c87,
    70: #848ea4,
    80: #abb3c1,
    90: #d5d8e0,
    95: #eaecf0,
    98: #f7f7f9,
    99: #fbfbfc,
    100: #fff,
  ),
  secondary: (
    0: #000,
    10: #121c2a,
    20: #273140,
    25: #323c4b,
    30: #3d4757,
    35: #495363,
    40: #555f6f,
    50: #6d7889,
    60: #8791a3,
    70: #a1acbe,
    80: #bdc7da,
    90: #d9e3f7,
    95: #ebf1ff,
    98: #f8f9ff,
    99: #fdfcff,
    100: #fff,
  ),
  tertiary: (
    0: #000,
    10: #322811,
    20: #614c17,
    25: #7a5f18,
    30: #947319,
    35: #af8818,
    40: #ca9d16,
    50: #d6ad47,
    60: #e0bc6d,
    70: #eacd91,
    80: #f2ddb5,
    90: #f9eeda,
    95: #fcf7ed,
    98: #fefcf8,
    99: #fefdfb,
    100: #fff,
  ),
  neutral: (
    0: #000,
    10: #1a1c1e,
    20: #2f3033,
    25: #3a3b3e,
    30: #46474a,
    35: #515255,
    40: #5e5e61,
    50: #76777a,
    60: #909094,
    70: #ababae,
    80: #c7c6c9,
    90: #e3e2e5,
    95: #f1f0f4,
    98: #faf9fc,
    99: #fdfcff,
    100: #fff,
    4: #0d0e11,
    6: #121316,
    12: #1e2022,
    17: #292a2d,
    22: #343538,
    24: #38393c,
    87: #dad9dd,
    92: #e9e8eb,
    94: #eeedf1,
    96: #f4f3f7,
  ),
  neutral-variant: (
    0: #000,
    10: #181c22,
    20: #2d3137,
    25: #383c42,
    30: #43474e,
    35: #4f535a,
    40: #5b5e66,
    50: #74777f,
    60: #8d9199,
    70: #a8abb3,
    80: #c3c6cf,
    90: #e0e2eb,
    95: #eef0f9,
    98: #f8f9ff,
    99: #fdfcff,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #fff,
  ),
);

//////////////////////////////////////////////////////////////////////////////
// Colour palette definitions
//////////////////////////////////////////////////////////////////////////////

$-rest: (
  secondary: map.get($-palettes, secondary),
  neutral: map.get($-palettes, neutral),
  neutral-variant: map.get($-palettes, neutral-variant),
  error: map.get($-palettes, error),
);
$-primary: map.merge(map.get($-palettes, primary), $-rest);
$-tertiary: map.merge(map.get($-palettes, tertiary), $-rest);


//////////////////////////////////////////////////////////////////////////////
// Typography configuration
//////////////////////////////////////////////////////////////////////////////

$-typography: (
  plain-family: 'Libre Baskerville',
  regular-weight: 400,
  medium-weight: 400,
  bold-weight: 700,
  use-system-variables: true,
  system-variables-prefix: 'ezc'
);


//////////////////////////////////////////////////////////////////////////////
// Theme - Requires colour palettes and typography
//////////////////////////////////////////////////////////////////////////////
$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $-primary,
    tertiary: $-tertiary,
    use-system-variables: true,
    system-variables-prefix: 'ezc'
  ),
  typography: $-typography,
  density: (
    scale: -1
  )
));

// Implementation done in "_themes.scss". So there is only one heavy file and
// this one can be @use in other places/components.
